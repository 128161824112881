import { FC, SVGProps } from 'react'

import Style from '../styles/AppTile.module.sass'

interface IAppTileProps {
  AppIcon: FC<SVGProps<SVGSVGElement>> | null
  content: string
  onClick: () => void
}

export const AppTile: FC<IAppTileProps> = (props) => {
  const { AppIcon, content, onClick } = props

  return (
    <div className={Style.tile} onClick={onClick} role='none'>
      <div>{AppIcon && <AppIcon role='image' aria-label={content} className={Style.logo} />}</div>
      <div className={Style.content}>{content}</div>
    </div>
  )
}
