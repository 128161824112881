import { FC, useEffect, useMemo, useState } from 'react'

import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'

import { useLanguages } from '../hooks/useLanguages'
import LoadingScreen from '../components/LoadingScreen'

import AppSlideEditor from '../components/AppSlideEditor'
import { AppSlidePositionEnum } from '../enums/AppSlidePosition'
import { IAppSlide } from '../models/api/IHomepageModels'
import { Button, useToast } from '@aurecon-creative-technologies/styleguide'
import { saveAppSlide } from '../api/HomepageService'

import { useRecoilRefresher_UNSTABLE } from 'recoil'
import { AppSlides } from '../stores/AppStore'

import Style from '../styles/HomeAdmin.module.sass'
import SystemBannerEditor from '../components/SystemBannerEditor'
import { actions } from '../config/permissions'
import { useUserHasPermissions } from '../hooks/useUserHasPermissions'

const HomeAdmin: FC = () => {
  const { t, i18n } = useLanguages()
  const { addToast } = useToast()
  const refreshAppSlides = useRecoilRefresher_UNSTABLE(AppSlides)

  const canEditDashboard = useUserHasPermissions(actions.EDIT_HOMEPAGE)
  const canAccessBanner = useUserHasPermissions(actions.EDIT_BANNER)

  useEffect(() => {
    if (appInsights) appInsights.trackPageView({ name: 'Home Admin' })
  }, [])

  const [dirtySlides, setDirtySlides] = useState<IAppSlide[]>([])

  const onDirty = (dirty: IAppSlide) => {
    setDirtySlides((slides) => {
      const existingIdx = slides.findIndex(
        (slide) =>
          slide.sortOrder === dirty.sortOrder && slide.language === dirty.language && slide.position === dirty.position,
      )
      if (existingIdx !== -1) {
        slides[existingIdx] = dirty
        return [...slides]
      } else {
        return [...slides, dirty]
      }
    })
  }

  const cancelEditing = () => {
    setDirtySlides([])
    refreshAppSlides()
  }

  const saveSlides = async () => {
    const saved = dirtySlides.map((slide) => {
      // save slide
      return saveAppSlide(slide)
    })
    const all = await Promise.all(saved)
    if (all.every((res) => res)) {
      addToast({
        type: 'success',
        title: t('popup_toast'),
        message: t('save_slides_success'),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
      setDirtySlides([])
      refreshAppSlides()
    } else {
      addToast({
        type: 'error',
        title: t('popup_toast'),
        message: t('save_slides_error'),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
    }
  }

  const canSave = useMemo(() => {
    if (!dirtySlides.length) return false
    if (
      dirtySlides.some((slide) => {
        return Object.keys(slide?.error || {}).length !== 0
      })
    )
      return false
    return true
  }, [dirtySlides])

  if (!canAccessBanner && !canEditDashboard) {
    location.hash = `#/`
    return null
  }

  if (!i18n)
    return (
      <Page>
        <LoadingScreen text={t('loading_translation')} />
      </Page>
    )

  return (
    <Page menu contentWrapper>
      {canEditDashboard && (
        <>
          <h1>{t('homeadmin_header')}</h1>
          <div className={Style.main}>
            <AppSlideEditor position={AppSlidePositionEnum.MAIN} onDirty={onDirty} />
          </div>
          <div className={Style.small}>
            <AppSlideEditor position={AppSlidePositionEnum.LEFT} onDirty={onDirty} />
            <AppSlideEditor position={AppSlidePositionEnum.RIGHT} onDirty={onDirty} />
          </div>
          <div className={Style.footer}>
            <div>
              <Button
                label='Cancel'
                size='medium'
                type='secondary'
                default
                cssClass='file-uploader-delete'
                disabled={dirtySlides.length === 0}
                onClick={() => cancelEditing()}
              />
              <Button
                label='Save'
                size='medium'
                type='primary'
                cssClass='file-uploader-delete'
                disabled={!canSave}
                onClick={() => saveSlides()}
              />
            </div>
          </div>
        </>
      )}
      {canAccessBanner && (
        <div className={Style.banner}>
          <SystemBannerEditor />
        </div>
      )}
    </Page>
  )
}

export default HomeAdmin
