import { useEffect } from 'react'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import { NomicAuthConfigLoader, NomicConfig, NomicToken } from '../stores/AppStore'
import { TokenManager } from '../api/TokenManager'

export const useNomicToken = () => {
  const nomicConfigLoader = useRecoilValueLoadable(NomicAuthConfigLoader)
  const [nomicConfig, setNomicConfig] = useRecoilState(NomicConfig)
  const [nomicToken, setNomicToken] = useRecoilState(NomicToken)

  useEffect(() => {
    if (nomicConfig || nomicConfigLoader.state !== 'hasValue' || !nomicConfigLoader.contents) return
    setNomicConfig(nomicConfigLoader.contents)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nomicConfigLoader.contents, nomicConfigLoader.state, setNomicConfig])

  useEffect(() => {
    if (!nomicConfig) return
    const getToken = async () => {
      const tknMgr = await TokenManager()
      const isTokenExpired = await tknMgr.checkTokenExpiration(nomicToken)
      const token = isTokenExpired ? await tknMgr.getNomicToken([], nomicConfig) : nomicToken
      setNomicToken(token)
    }

    getToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nomicConfig, setNomicToken])

  return { nomicToken }
}
