import { FC, useMemo } from 'react'

import { ReactComponent as RecallMenuIcon } from '../../src/assets/Recall_menu_icon.svg'
import { ReactComponent as GPTMenuIcon } from '../../src/assets/ChatGPT_menu_icon.svg'
import { ReactComponent as WinWiseLogo } from '../../src/assets/WinWise_logo.svg'
import { ReactComponent as CRALogo } from '../../src/assets/CRA_logo.svg'

import { Splide, SplideSlide } from '@splidejs/react-splide'
import { useMediaQuery } from 'react-responsive'

import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'
import { AppTile } from '../components/AppTile'
import { useChangeChat } from '../hooks/useChangeChat'
import { AppPill } from '../components/AppPill'
import { AppColour } from '../enums/AppColourConstants'
import { AppShortcut } from '../components/AppShortcut'
import { UI_WIDTH_COLLAPSE } from '../config/config'
import { FeatureFlagEnum } from '../enums/FeatureFlagEnum'
import { useShowFeature } from '../hooks/useShowFeature'

import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/skyblue'
import '@splidejs/react-splide/css/sea-green'
import '@splidejs/react-splide/css/core'
import { useLanguages } from '../hooks/useLanguages'
import LoadingScreen from '../components/LoadingScreen'

import AppSlideViewer from '../components/AppSlideViewer'
import { AppSlidePositionEnum } from '../enums/AppSlidePosition'

import Style from '../styles/Home.module.sass'

const Home: FC = () => {
  const { openRecallChat, openChatGPT, openWinWise, openBamboo, openCustomRecallApp, openBHPStandards } =
    useChangeChat()
  const showBambooChat = useShowFeature(FeatureFlagEnum.ShowBambooChat)
  const showCustomRecallChat = useShowFeature(FeatureFlagEnum.ShowCustomRecallApp)
  const showBHPStandards = useShowFeature(FeatureFlagEnum.ShowBHPStandardsApp)
  const showHomepageSlides = useShowFeature(FeatureFlagEnum.ShowHomepageSlides)

  const isMobile = useMediaQuery({ maxWidth: UI_WIDTH_COLLAPSE })

  const { t, i18n } = useLanguages()

  if (appInsights) appInsights.trackPageView({ name: 'Home' })

  const appSlides = useMemo(() => {
    return showHomepageSlides.enabled ? (
      <>
        <AppSlideViewer position={AppSlidePositionEnum.MAIN} />
        <div className={Style.small}>
          <AppSlideViewer position={AppSlidePositionEnum.LEFT} />
          <AppSlideViewer position={AppSlidePositionEnum.RIGHT} />
        </div>
      </>
    ) : null
  }, [showHomepageSlides.enabled])

  const appSplides = useMemo(() => {
    return (
      <>
        <h1>{t('home_header')}</h1>
        <div className={Style.tiles}>
          <Splide aria-label='Chat Types' options={{ arrows: false, autoplay: true, loop: true, rewind: true }}>
            <SplideSlide>
              <AppTile AppIcon={RecallMenuIcon} content={t('recall_header')} onClick={openRecallChat} />
            </SplideSlide>
            <SplideSlide>
              <AppTile AppIcon={GPTMenuIcon} content={t('securegpt_header')} onClick={openChatGPT} />
            </SplideSlide>
            <SplideSlide>
              <AppTile AppIcon={WinWiseLogo} content={t('winwise_header')} onClick={openWinWise} />
            </SplideSlide>
            {showCustomRecallChat.enabled && (
              <SplideSlide>
                <AppTile AppIcon={CRALogo} content={t('custom_app_header')} onClick={openCustomRecallApp} />
              </SplideSlide>
            )}
            {showBHPStandards.enabled && (
              <SplideSlide>
                <AppTile
                  AppIcon={null}
                  content={
                    'Find and interpret the BHP Standards. Discover the standards that may apply to your documents'
                  }
                  onClick={openBHPStandards}
                />
              </SplideSlide>
            )}
          </Splide>
        </div>
      </>
    )
  }, [
    openBHPStandards,
    openChatGPT,
    openCustomRecallApp,
    openRecallChat,
    openWinWise,
    showBHPStandards.enabled,
    showCustomRecallChat.enabled,
    t,
  ])

  const appTiles = useMemo(() => {
    return (
      <>
        <h1>{t('home_header')}</h1>

        <div className={Style.tiles}>
          <AppTile AppIcon={RecallMenuIcon} content={t('recall_header')} onClick={openRecallChat} />
          <AppTile AppIcon={GPTMenuIcon} content={t('securegpt_header')} onClick={openChatGPT} />
          <AppTile AppIcon={WinWiseLogo} content={t('winwise_header')} onClick={openWinWise} />
          {showCustomRecallChat.enabled && (
            <AppTile AppIcon={CRALogo} content={t('custom_app_header')} onClick={openCustomRecallApp} />
          )}
          {showBambooChat.enabled && <AppTile AppIcon={null} content={t('bamboo_header')} onClick={openBamboo} />}

          {showBHPStandards.enabled && <AppTile AppIcon={null} content={t('bhp_header')} onClick={openBHPStandards} />}
        </div>
      </>
    )
  }, [
    openBHPStandards,
    openBamboo,
    openChatGPT,
    openCustomRecallApp,
    openRecallChat,
    openWinWise,
    showBHPStandards.enabled,
    showBambooChat.enabled,
    showCustomRecallChat.enabled,
    t,
  ])
  const appPills = useMemo(() => {
    return !showHomepageSlides.enabled ? (
      <>
        <h1>{t('home_header2')}</h1>

        <div className={Style.pills}>
          <AppPill content={t('recall_pin')} onClick={openRecallChat} colour={AppColour.RECALL_CHAT} />
          <AppPill content={t('securegpt_pin')} onClick={openChatGPT} colour={AppColour.GPT_CHAT} />
          <AppPill content={t('winwise_pin')} onClick={openWinWise} colour={AppColour.WINWISE} />
          {showBambooChat.enabled && (
            <AppPill content={t('bamboo_pin')} onClick={openBamboo} colour={AppColour.BAMBOO} />
          )}
          {showCustomRecallChat.enabled && (
            <AppPill
              content='Processes requests from a document'
              onClick={openCustomRecallApp}
              colour={AppColour.CUSTOM_RECALL_APP}
            />
          )}
          {showBHPStandards.enabled && (
            <AppPill content={t('bhp_pin')} onClick={openBHPStandards} colour={AppColour.BHP_STANDARDS} />
          )}
        </div>
      </>
    ) : null
  }, [
    showHomepageSlides.enabled,
    t,
    openRecallChat,
    openChatGPT,
    openWinWise,
    showBambooChat.enabled,
    openBamboo,
    showCustomRecallChat.enabled,
    openCustomRecallApp,
    showBHPStandards.enabled,
    openBHPStandards,
  ])

  const appShortcuts = useMemo(() => {
    return !showHomepageSlides.enabled ? (
      <>
        <hr className={Style.divider} />

        <h2>{t('home_header3')}</h2>
        <div className={Style.shortcuts}>
          <AppShortcut AppIcon={RecallMenuIcon} content='Recall Chat' onClick={openRecallChat} />
          <AppShortcut AppIcon={GPTMenuIcon} content='Secure ChatGPT' onClick={openChatGPT} />
          <AppShortcut AppIcon={WinWiseLogo} content='WinWise' onClick={openWinWise} />
          {showCustomRecallChat.enabled && (
            <AppShortcut AppIcon={CRALogo} content='Custom Recall App' onClick={openCustomRecallApp} />
          )}
          {showBHPStandards.enabled && (
            <AppShortcut AppIcon={null} content='BHP Standards' onClick={openBHPStandards} />
          )}
        </div>
      </>
    ) : null
  }, [
    openBHPStandards,
    openChatGPT,
    openCustomRecallApp,
    openRecallChat,
    openWinWise,
    showBHPStandards.enabled,
    showCustomRecallChat.enabled,
    showHomepageSlides.enabled,
    t,
  ])

  if (!i18n)
    return (
      <Page>
        <LoadingScreen text={t('loading_translation')} />
      </Page>
    )
  return (
    <Page menu contentWrapper noGrow>
      <>
        {isMobile ? (
          appSplides
        ) : (
          <>
            {appSlides}
            {appTiles}
            {appPills}
            {appShortcuts}
          </>
        )}
      </>
    </Page>
  )
}

export default Home
