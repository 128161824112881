import { useRecoilValue, useSetRecoilState } from 'recoil'
import { ChatSession, LoadingAnswer, NomicSession, ShowExtendedInput } from '../stores/AppStore'
import { AppRoute } from '../enums/AppRouteConstants'
import { logAppAccess } from '../helpers/utils'

export const useChangeChat = () => {
  const setChatSession = useSetRecoilState(ChatSession)
  const setNomicSession = useSetRecoilState(NomicSession)

  const loadingAnswer = useRecoilValue(LoadingAnswer)
  const setShowExtendedInput = useSetRecoilState(ShowExtendedInput)

  const openRecallChat = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)
    setShowExtendedInput(true)
    logAppAccess('Recall Chat')
    location.hash = `#/${AppRoute.RECALL_CHAT}`
  }

  const openChatGPT = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)
    logAppAccess('Secure Chat GPT')
    location.hash = `#/${AppRoute.GPT_CHAT}`
  }

  const openWinWise = (openNewTab?: boolean) => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)
    logAppAccess('Winwise')
    const url = 'https://winwise.aurecongroup.digital/'
    if (openNewTab) {
      window.open(url, '_blank', 'noopener')
      return
    }
    location.href = url
  }

  const openCode = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)
    logAppAccess('Code Assistant')
    location.href = `#/${AppRoute.CODE_CHAT}`
  }

  const openBamboo = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)
    logAppAccess('Bamboo Chat')
    location.href = `#/${AppRoute.BAMBOO_CHAT}`
  }

  const openCustomRecallChat = (id?: string) => {
    if (loadingAnswer || !id) return
    setChatSession(null)
    setNomicSession(null)
    logAppAccess('Custom Recall Chat')
    location.href = `#/${AppRoute.CUSTOM_RECALL_APP_CHAT}/${id}`
  }

  const openCustomRecallApp = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)
    logAppAccess('Custom Recall App')
    location.href = `#/${AppRoute.CUSTOM_RECALL_APP}`
  }

  const openBHPStandards = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)
    logAppAccess('BHP Standards')
    location.href = `#/${AppRoute.BHP_STANDARDS}`
  }
  return {
    openRecallChat,
    openChatGPT,
    openWinWise,
    openCode,
    openBamboo,
    openCustomRecallApp,
    openCustomRecallChat,
    openBHPStandards,
  }
}
