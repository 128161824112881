import { FC, useEffect, useMemo, useState } from 'react'
import RecallJoditEditor from './common/RecallJoditEditor'
import { Button, Toggle, useToast } from '@aurecon-creative-technologies/styleguide'
import Style from '../styles/SystemBannerEditor.module.sass'
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { Language, SystemBannerLoader } from '../stores/AppStore'
import { addSystemBannerInfo, getSystemBannerInfo, ISystemBannerInfo } from '../api/AppService'
import LanguageSwitch from './LanguageSwitch'
import { ResponseData } from '../models/api/IResponse'

const SystemBannerEditor: FC = () => {
  const systemBannerLoader = useRecoilValueLoadable(SystemBannerLoader)
  const refreshSystemBanner = useRecoilRefresher_UNSTABLE(SystemBannerLoader)

  const appLanguage = useRecoilValue(Language)
  const [systemBanner, setSystemBanner] = useState<ISystemBannerInfo>({
    language: appLanguage,
    message: '',
    enabled: false,
  })
  const [loading, setLoading] = useState(false)

  const { addToast } = useToast()

  const joditCofig = useMemo(() => {
    return {
      readonly: loading,
      buttons: 'bold,italic,underline,strikethrough,fontsize,paragraph,lineHeight,superscript,subscript',
      statusbar: false,
      showTooltip: false,
      sizeLG: 12,
      sizeSM: 12,
      sizeMD: 12,
      disablePlugins: 'add-new-line',
    }
  }, [loading])

  useEffect(() => {
    if (systemBannerLoader.state !== 'hasValue' || !systemBannerLoader.contents) return
    setSystemBanner(systemBannerLoader.contents)
  }, [systemBannerLoader.contents, systemBannerLoader.state])

  const resetSystemBanner = () => {
    if (systemBannerLoader.state !== 'hasValue' || !systemBannerLoader.contents) return
    setSystemBanner(systemBannerLoader.contents)
  }

  const onLangChange = async (language: string) => {
    setLoading(true)
    const response = ResponseData(await getSystemBannerInfo({ language }))
    setLoading(false)
    if (!response) return
    setSystemBanner(response)
  }

  const onSystemBannerSave = async () => {
    setLoading(true)
    const res = ResponseData(await addSystemBannerInfo(systemBanner))
    setLoading(false)

    refreshSystemBanner()

    if (!res) {
      addToast({
        type: 'error',
        message: 'Error updating system banner',
        timeout: 5000,
      })
      return
    }

    addToast({
      type: 'success',
      message: 'System banner successfully update',
      timeout: 5000,
    })
  }

  return (
    <div>
      <h1 className={Style.title}>System Banner</h1>
      <div className={Style.systemBanner}>
        <RecallJoditEditor
          config={joditCofig}
          value={systemBanner?.message || ''}
          onChange={(message) => setSystemBanner((sb) => ({ ...sb, message }))}
          maxLength={500}
        />
        <div className={Style.toggleArea}>
          <Toggle
            label='Visibility'
            value={systemBanner.enabled}
            cssClass={Style.toggle}
            onChange={(enabled) => setSystemBanner((sb) => ({ ...sb, enabled }))}
          />
          <LanguageSwitch value={systemBanner?.language || appLanguage} onChange={onLangChange} isRightAligned={true} />
        </div>
      </div>

      <div className={Style.footer}>
        <div>
          <Button
            label='Cancel'
            size='medium'
            type='secondary'
            default
            cssClass='file-uploader-delete'
            onClick={resetSystemBanner}
          />
          <Button
            label='Save'
            size='medium'
            type='primary'
            cssClass='file-uploader-delete'
            loading={loading}
            onClick={onSystemBannerSave}
          />
        </div>
      </div>
    </div>
  )
}

export default SystemBannerEditor
