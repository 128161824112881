import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Page from '../components/Page'
import Style from '../styles/Bamboo.module.sass'
import PDFViewer from '../components/PDFViewer'
import {
  Tooltip,
  useToast,
  Table,
  OverflowMenu,
  TableRow,
  TableCell,
  Icon,
} from '@aurecon-creative-technologies/styleguide'
import { IBambooEntityModel } from '../models/IBambooEntityModel'
import classNames from 'classnames'
import BambooPropertyModal from '../components/modals/BambooPropertyModal'
import { IBambooPropertyModel } from '../models/IBambooPropertyModel'
import BambooEntityModal from '../components/modals/BambooEntityModal'
import RecallButton from '../components/common/RecallButton'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'
import { AppRoute } from '../enums/AppRouteConstants'
import ManageFileModal from '../components/modals/ManageFileModal'
import { v4 as uuidv4 } from 'uuid'
import ConfirmModal from '../components/modals/ConfirmModal'
import { BambooPropertyEnum } from '../enums/BambooPropertyEnum'
import { exportResponse, generateBambooResponse, getBambooChat } from '../api/BambooChatService'
import LoadingScreen from '../components/LoadingScreen'
import { useNavigate, useParams } from 'react-router-dom'
import { getBambooFile } from '../api/FileService'
import { IGenerateBambooModel } from '../models/api/IBambooChatModel'
import { usefileDownload } from '../hooks/useFileDownload'
import { useLanguages } from '../hooks/useLanguages'
import { getTextFromPdf } from '../helpers/utils'

interface IChatBambooProps {
  chatId: string
  file?: File
  content?: string
}

const DefaultEntity = {
  id: '',
  name: '',
  description: '',
  properties: [],
}

export const DefaultProperty = {
  id: '',
  name: '',
  description: '',
  type: BambooPropertyEnum.STRING,
  required: true,
  matches: [],
}

const ContextMenuItemIds = {
  EDIT: '1',
  DELETE: '2',
}

const ChatBamboo: FC<IChatBambooProps> = (props) => {
  const navigate = useNavigate()
  const { chatId, file, content } = props
  const { chatId: cId } = useParams()
  const [filePages, setFilePages] = useState(0)
  const [fileUpload, setFileUpload] = useState(file ? () => URL.createObjectURL(file) : '')
  const [filename, setFilename] = useState(file?.name ?? '')
  const [openManageFile, setOpenManageFile] = useState(false)
  const [exporting, setExporting] = useState(false)
  const [currentFile, setCurrentFile] = useState(file)
  const [pdfViewerId] = useState(`pdf-viewer-${uuidv4()}`)
  const [fileId, setFileId] = useState<string>()
  const [currentChatId, setCurrentChatId] = useState(cId ?? chatId)

  const [fileContent, setFileContent] = useState('')
  const [entities, setEntities] = useState<IBambooEntityModel[]>([])
  const [lastSaveEntities, setLastSaveEntities] = useState<string>('')
  const [selectedEntityId, setSelectedEntityId] = useState('')
  const [editEntity, setEditEntity] = useState<IBambooEntityModel | null>(cId ? null : DefaultEntity)
  const [deleteEntity, setDeleteEntity] = useState<IBambooEntityModel | null>(null)
  const [editProperty, setEditProperty] = useState<IBambooPropertyModel | null>(null)
  const [deleteProperty, setDeleteProperty] = useState<IBambooPropertyModel | null>(null)
  const [isUploading, setIsUploading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [generatingResponse, setGeneratingResponse] = useState(false)
  const [showEntityTooltips, setShowEntityTooltips] = useState<string[]>([])
  const [showEntityDescTooltips, setShowEntityDescTooltips] = useState<string[]>([])
  const [showPropertyDescTooltips, setShowPropertyDescTooltips] = useState<string[]>([])

  const refs = useRef<{ [key: string]: HTMLParagraphElement | null }>({})
  const entityDescRefs = useRef<{ [key: string]: HTMLSpanElement | null }>({})
  const propertyDataRefs = useRef<{ [key: string]: HTMLSpanElement | null }>({})

  const { t } = useLanguages()
  const [contextMenu, setContextMenu] = useState([
    { id: ContextMenuItemIds.EDIT, label: t('edit') },
    { id: ContextMenuItemIds.DELETE, label: t('delete') },
  ])

  useEffect(() => {
    if (!content) return
    setFileContent(content)
  }, [content])

  useEffect(() => {
    setContextMenu([
      { id: ContextMenuItemIds.EDIT, label: t('edit') },
      { id: ContextMenuItemIds.DELETE, label: t('delete') },
    ])
  }, [t])

  const { addToast } = useToast()
  const { downloadFile } = usefileDownload()

  const handleFileUpdate = useCallback(
    async (updatedFile: File, disableToast?: boolean) => {
      setIsUploading(true)
      setFileContent('')
      setOpenManageFile(false)

      try {
        const response = await getTextFromPdf(updatedFile)

        if (response) {
          setFilename(updatedFile.name)
          setFileUpload(URL.createObjectURL(updatedFile))
          setCurrentFile(updatedFile)
          setIsUploading(false)
          setFileContent(response)

          if (!disableToast) {
            setFileId('')
            addToast({
              type: 'success',
              title: t('popup_toast'),
              message: t('bamboo_mess10'),
              timeout: 5000,
              timeLabel: t('popup_toast_timelabel'),
            })
          }
          return
        }
        addToast({
          type: 'error',
          title: t('popup_toast2'),
          message: t('bamboo_mess11'),
          timeout: 5000,
          timeLabel: t('popup_toast_timelabel'),
        })
      } catch (error) {
        addToast({
          type: 'error',
          title: t('popup_toast2'),
          message: t('bamboo_mess12'),
          timeout: 5000,
          timeLabel: t('popup_toast_timelabel'),
        })
      }
      setIsUploading(false)
    },
    [addToast, t],
  )

  const reAssignEntities = useCallback((updatedEntities: IBambooEntityModel[], bambooRes: IGenerateBambooModel[]) => {
    updatedEntities.forEach((entity) => {
      const resEntity = bambooRes.find((br) => br.id === entity.id)
      if (!resEntity) return

      const properties: IBambooPropertyModel[] = entity.properties.map((p) => ({ ...p, id: p.id || uuidv4() }))
      entity.results = []
      resEntity.results.flatMap((r) => r.matches).forEach((match) => entity.results?.push(match.data))
      entity.properties = properties
    })
  }, [])

  useEffect(() => {
    if (!cId || currentFile || isLoading) return

    const asyncFunc = async () => {
      setIsLoading(true)
      const response = await getBambooChat({ chatId: cId })
      const data = response?.data
      if (!data) {
        setIsLoading(false)
        return
      }
      const bambooRes = data.results
      const updatedEntities = [...data.entities]
      reAssignEntities(updatedEntities, bambooRes)

      setEntities(updatedEntities)

      setLastSaveEntities(JSON.stringify(updatedEntities))
      setSelectedEntityId(data.entities[0]?.id)
      const fileInfo = data.files[0]
      setFilename(fileInfo.name)
      setFileId(fileInfo.id)
      setCurrentChatId(cId)
      const blobRes = await getBambooFile({ chatId: cId })
      const blob = blobRes?.data
      if (!blob) {
        setIsLoading(false)
        return
      }
      const file = new File([blob], fileInfo.name, { type: fileInfo.mimetype })
      setCurrentFile(file)
      handleFileUpdate(file, true)
      setIsLoading(false)
    }

    asyncFunc()
  }, [cId, currentFile, handleFileUpdate, isLoading, reAssignEntities])

  useEffect(() => {
    const closeTabHandler = (e: Event) => {
      e.stopPropagation()
      e.preventDefault()
      localStorage.removeItem(filename ?? '')
    }
    window.addEventListener('unload', closeTabHandler)
    return () => {
      window.removeEventListener('unload', closeTabHandler)
      localStorage.removeItem(filename ?? '')
    }
  }, [filename])

  const selectedEntity = useMemo(() => {
    return entities.find((e) => e.id === selectedEntityId)
  }, [entities, selectedEntityId])

  const handleGenerateResponse = async () => {
    setGeneratingResponse(true)
    const res = await generateBambooResponse({
      chatId: currentChatId,
      fileId,
      file: currentFile,
      fileContent,
      entities,
    })

    const data = res?.data

    if (!data) {
      addToast({
        type: 'error',
        title: t('popup_toast2'),
        message: t('bamboo_err3'),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
      setGeneratingResponse(false)
      return
    }

    const bambooRes = data.results
    const updatedEntities = [...entities]

    reAssignEntities(updatedEntities, bambooRes)

    setEntities(updatedEntities)
    setLastSaveEntities(JSON.stringify(updatedEntities))
    setFileId(data.files[0].id)
    const newChatId = data.chatId
    setCurrentChatId(newChatId)
    navigate(`/${AppRoute.BAMBOO_CHAT}/${newChatId}`, { replace: true })

    addToast({
      type: 'success',
      title: t('popup_toast'),
      message: t('bamboo_mess13'),
      timeout: 5000,
      timeLabel: t('popup_toast_timelabel'),
    })

    setGeneratingResponse(false)
  }

  const onSaveEntity = (entity: IBambooEntityModel) => {
    const updatedEntities = [...entities]
    const foundEntity = updatedEntities.find((e) => e.id === entity.id)

    if (!entity) {
      addToast({
        type: 'error',
        title: t('popup_toast2'),
        message: foundEntity ? t('bamboo_mess14') : t('bamboo_mess15'),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
      return
    }

    if (foundEntity) {
      foundEntity.name = entity.name
      foundEntity.description = entity.description
      setEntities(updatedEntities)
    } else {
      setEntities([entity, ...updatedEntities])
    }

    setSelectedEntityId(entity.id)
  }

  const onDeleteEntity = (entity: IBambooEntityModel | null) => {
    if (!entity) {
      addToast({
        type: 'error',
        title: t('popup_toast2'),
        message: t('bamboo_mess7'),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
      return
    }

    const updatedEntities = entities.filter((e) => e.id !== entity.id)

    setEntities(updatedEntities)
    setSelectedEntityId(updatedEntities[0]?.id || '')
    setDeleteEntity(null)

    addToast({
      type: 'success',
      title: t('popup_toast'),
      message: t('bamboo_mess9'),
      timeout: 5000,
      timeLabel: t('popup_toast_timelabel'),
    })
  }

  const onSaveProperty = (property: IBambooPropertyModel) => {
    const updatedEntities = [...entities]
    const entity = updatedEntities.find((e) => e.id === selectedEntityId)

    if (!entity) return

    const foundProperty = entity.properties.find((p) => p.id === property.id)

    if (!property) {
      addToast({
        type: 'error',
        title: t('popup_toast2'),
        message: foundProperty ? t('bamboo_mess19') : t('bamboo_mess20'),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
      return
    }

    if (foundProperty) {
      foundProperty.name = property.name
      foundProperty.description = property.description
      foundProperty.required = property.required
      foundProperty.type = property.type
    } else {
      entity.properties = [...entity.properties, property]
    }

    setEntities(updatedEntities)

    setEditProperty(null)
  }

  const onDeleteProperty = (property: IBambooPropertyModel | null) => {
    if (!property) {
      addToast({
        type: 'error',
        title: t('popup_toast2'),
        message: t('bamboo_mess7'),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
      return
    }

    const updatedEntities = [...entities]
    const entity = updatedEntities.find((e) => e.id === selectedEntityId)

    if (!entity) return

    entity.properties = entity.properties.filter((p) => p.id !== property.id)

    setEntities(updatedEntities)
    setDeleteProperty(null)

    addToast({
      type: 'success',
      title: t('popup_toast'),
      message: t('bamboo_mess9'),
      timeout: 5000,
      timeLabel: t('popup_toast_timelabel'),
    })
  }

  const deleteAllPropertiesForEntity = () => {
    const updatedEntities = [...entities]
    const entity = updatedEntities.find((e) => e.id === selectedEntityId)

    if (!entity) return
    entity.properties = []

    setEntities(updatedEntities)

    addToast({
      type: 'success',
      title: t('popup_toast'),
      message: t('bamboo_mess8'),
      timeout: 5000,
      timeLabel: t('popup_toast_timelabel'),
    })
  }

  const openPdf = () => {
    if (!fileUpload || isUploading || isLoading) return
    localStorage.setItem(filename, fileUpload)
    window.open(`#/${AppRoute.PDF_VIEWER}/${filename}`, '_blank')
  }

  const exportResponseToFile = async () => {
    setExporting(true)
    const exportedFile = await exportResponse({ chatId: currentChatId })

    if (!exportedFile?.data) return
    downloadFile(exportedFile.data, exportedFile.headers?.get('content-disposition')?.split('filename=')[1] ?? '')
    setExporting(false)
  }

  const doAllPropertiesHaveMatches = useMemo(() => {
    const bambooResults = entities.flatMap((e) => e.results)
    const allEntitiesHaveProperties = entities.every((e) => !!e.properties.length)
    const allPropertiesHaveResponse = entities.every((e) =>
      e.properties.map((p) => p.name).every((val) => Object.keys(e?.results?.[0] || {}).includes(val)),
    )

    return allEntitiesHaveProperties && allPropertiesHaveResponse && !!bambooResults.length
  }, [entities])

  const haveEntityFieldsChanged = useMemo(() => {
    return lastSaveEntities !== JSON.stringify(entities)
  }, [entities, lastSaveEntities])

  const renderCellContent = (id: string, val: string | boolean | number, propertyType: string) => {
    if (propertyType === BambooPropertyEnum.BOOLEAN) {
      return val ? <Icon type='check' colour='green' /> : <Icon type='close' colour='red' />
    }

    return (
      <div
        role='none'
        onFocus={() => handleShowPropertyDescTooltip(id)}
        onMouseOver={() => handleShowPropertyDescTooltip(id)}
      >
        {showPropertyDescTooltips.includes(id) && (
          <Tooltip show={<span>{val}</span>} defaultUp={false}>
            <span className={Style.cellContent} ref={(el) => (propertyDataRefs.current[id] = el)}>
              {val}
            </span>
          </Tooltip>
        )}
        {!showPropertyDescTooltips.includes(id) && (
          <span className={Style.cellContent} ref={(el) => (propertyDataRefs.current[id] = el)}>
            {val}
          </span>
        )}
      </div>
    )
  }

  const handleEntityHoverOver = (id: string) => {
    if (id !== selectedEntityId) return
    handleShowEntityTooltip(id)
    handleShowEntityDescTooltip(id)
  }

  const handleShowEntityTooltip = (entityId: string) => {
    const items: string[] = []

    const element = refs.current[entityId]
    if (element && element.scrollWidth > element.clientWidth) items.push(entityId)

    setShowEntityTooltips(items)
  }

  const handleShowEntityDescTooltip = (entityId: string) => {
    const items: string[] = []

    const element = entityDescRefs.current[entityId]
    if (element && element.scrollHeight > element.clientHeight) items.push(entityId)

    setShowEntityDescTooltips(items)
  }

  const renderEntityName = (entity: IBambooEntityModel) => {
    const { id, name } = entity

    if (!showEntityTooltips.includes(id))
      return (
        <p className={Style.entityName} ref={(el) => (refs.current[id] = el)}>
          {name}
        </p>
      )

    return (
      <Tooltip show={<div className={Style.wordWrap}>{name}</div>} offset={{ top: -15, left: 0 }}>
        <p className={Style.entityName} ref={(el) => (refs.current[id] = el)}>
          {name}
        </p>
      </Tooltip>
    )
  }

  const renderEntityDesc = (entity: IBambooEntityModel) => {
    const { id, description } = entity

    if (id !== selectedEntityId) return null

    if (!showEntityDescTooltips.includes(id) && id === selectedEntityId)
      return (
        <span className={Style.description} ref={(el) => (entityDescRefs.current[id] = el)}>
          {description}
        </span>
      )

    return (
      <Tooltip
        show={<div className={Style.wordWrap}>{description}</div>}
        offset={{ top: -25, left: 0 }}
        cssClass={Style.tooltipDescription}
        defaultUp={false}
      >
        <span className={Style.description} ref={(el) => (entityDescRefs.current[id] = el)}>
          {description}
        </span>
      </Tooltip>
    )
  }

  const handleShowPropertyDescTooltip = (id: string) => {
    const items: string[] = []

    const element = propertyDataRefs.current[id]
    if (element && element.scrollWidth > element.clientWidth) items.push(id)

    setShowPropertyDescTooltips(items)
  }

  const renderPropertyCell = () => {
    if (generatingResponse) return null

    return selectedEntity?.results?.map((result, i) => {
      return (
        <TableRow key={`${Object.keys(result).join()}-${i}`}>
          {selectedEntity.properties.map((property) => (
            <TableCell key={property.id} cellClass={Style.cellWrapper}>
              {renderCellContent(property.id, result[property.name], property.type)}
            </TableCell>
          ))}
        </TableRow>
      )
    })
  }

  const renderPdfViewer = () => {
    if (isUploading || isLoading) return <LoadingScreen size='extra small' text='' />
    if (!fileUpload) return null
    return (
      <PDFViewer file={fileUpload} showOnlyAsPreview={true} height={70} onReady={setFilePages} viewerId={pdfViewerId} />
    )
  }

  const renderLoaderOrEntityMessage = () => {
    return isLoading ? (
      <LoadingScreen text={t('load_response')} />
    ) : (
      <div className={Style.propertyContent}>
        <p>{t('bamboo_desc2')}</p>
        <RecallButton
          chatType={ChatTypeEnum.BAMBOO}
          label={t('create_property')}
          type='primary'
          disabled={!selectedEntityId}
          onClick={() => setEditProperty(DefaultProperty)}
        />
      </div>
    )
  }

  return (
    <Page menu contentWrapper cssClassName={Style.chatWrapper}>
      <div className={Style.chatHeader}>
        <div className={Style.leftSide}>
          <div className={Style.pdfThumbnail} role='none' onClick={openPdf}>
            {renderPdfViewer()}
          </div>

          <div className={Style.selectedFile}>
            <div className={Style.selectedFileText}>{t('selected_file')}</div>
            <div className={Style.selectedFileName}>
              <span
                role='none'
                className={classNames(Style.fileName, {
                  [Style.removed]: !fileUpload && !isUploading && !isLoading,
                })}
                onClick={openPdf}
              >
                {filename}
              </span>
              {!!filePages && (
                <span className={Style.filePage}>
                  {' - '}
                  {filePages} {t('bamboo_page')}
                </span>
              )}
              {!fileUpload && !isUploading && !isLoading && (
                <span className={Style.fileDeletedIcon}>
                  <Tooltip show={t('bamboo_mess5')}>
                    <Icon type='info' colour='#E10000' size='15px' />
                  </Tooltip>
                </span>
              )}
            </div>
          </div>
          <div className={Style.manageFile}>
            <RecallButton
              chatType={ChatTypeEnum.BAMBOO}
              label={t('manage_file')}
              type='primary'
              cssClass={Style.manageFileButton}
              onClick={() => setOpenManageFile(true)}
              disabled={isUploading || generatingResponse}
            />
          </div>
        </div>

        <div className={Style.rightSide}>
          <RecallButton
            chatType={ChatTypeEnum.BAMBOO}
            label={t('generate_res')}
            type='primary'
            onClick={handleGenerateResponse}
            disabled={
              isUploading ||
              !fileContent ||
              !entities.length ||
              !entities.every((e) => e.properties.length) ||
              generatingResponse
            }
          />
          <RecallButton
            chatType={ChatTypeEnum.BAMBOO}
            type='icon-round'
            icon='file_download'
            cssClass={Style.exportButton}
            onClick={exportResponseToFile}
            disabled={
              !doAllPropertiesHaveMatches || isUploading || generatingResponse || exporting || haveEntityFieldsChanged
            }
          />
        </div>
      </div>
      <div className={Style.chatContent}>
        <div className={Style.entitySideBar}>
          <p>Entity</p>
          <div className={Style.createNewEntity}>
            <RecallButton
              chatType={ChatTypeEnum.BAMBOO}
              icon='add_circle'
              type='custom'
              label={t('create_entity')}
              onClick={() => setEditEntity(DefaultEntity)}
              disabled={generatingResponse || !currentFile}
              cssClass={Style.createEntityButton}
            />
          </div>
          <div className={Style.entityList}>
            {entities.map((entity) => (
              <div
                role='none'
                key={entity.id}
                className={classNames({
                  [Style.selected]: entity.id === selectedEntityId,
                  [Style.entityCard]: true,
                })}
                onClick={() => setSelectedEntityId(entity.id)}
                onMouseOver={() => {
                  handleEntityHoverOver(entity.id)
                }}
                onFocus={() => {
                  handleEntityHoverOver(entity.id)
                }}
              >
                <div className={Style.titleRow}>
                  {renderEntityName(entity)}

                  {entity.id === selectedEntityId && (
                    <OverflowMenu
                      items={contextMenu}
                      size='extra small'
                      icon='more_vert'
                      default
                      cssClass='entity-overflow-menu'
                      onSelectItem={(item) =>
                        item.id === ContextMenuItemIds.EDIT ? setEditEntity(entity) : setDeleteEntity(entity)
                      }
                    />
                  )}
                </div>
                {renderEntityDesc(entity)}
              </div>
            ))}
          </div>
        </div>
        <div className={Style.propertyWrapper}>
          <div className={Style.actionButtons}>
            <RecallButton
              chatType={ChatTypeEnum.BAMBOO}
              icon='add_circle'
              type='custom'
              label={t('create_property')}
              disabled={!selectedEntityId || generatingResponse}
              onClick={() => setEditProperty(DefaultProperty)}
            />
            <RecallButton
              chatType={ChatTypeEnum.BAMBOO}
              icon='restart_alt'
              type='custom'
              label={t('clear_all')}
              cssClass={Style.clearAllButton}
              onClick={() => deleteAllPropertiesForEntity()}
              disabled={!selectedEntityId || !selectedEntity?.properties.length || generatingResponse}
            />
          </div>

          {!selectedEntity?.properties.length ? (
            renderLoaderOrEntityMessage()
          ) : (
            <div className={Style.propertyTable}>
              <Table
                headers={selectedEntity.properties.map((p) => ({
                  label: p.name,
                  moreOptions: {
                    items: contextMenu,
                    onSelected: (item) =>
                      item.id === ContextMenuItemIds.EDIT ? setEditProperty(p) : setDeleteProperty(p),
                  },
                  labelTooltipOptions: {
                    maxWidth: 200,
                  },
                }))}
                cssClass='property-overflow-menu'
              >
                {renderPropertyCell()}
              </Table>
              {generatingResponse && <LoadingScreen text={t('gen_response')} />}
            </div>
          )}
        </div>
      </div>
      {!!editEntity && (
        <BambooEntityModal
          open={!!editEntity}
          onSave={onSaveEntity}
          onClose={() => setEditEntity(null)}
          selectedEntity={editEntity}
        />
      )}
      <ConfirmModal
        open={!!deleteEntity}
        title={t('delete_entity')}
        message={t('bamboo_mess17', { entityName: deleteEntity?.name || '' })}
        labelYes={t('delete')}
        size='small'
        onClose={() => setDeleteEntity(null)}
        onSave={() => onDeleteEntity(deleteEntity)}
        chatType={ChatTypeEnum.BAMBOO}
      />
      {!!editProperty && (
        <BambooPropertyModal
          open={!!editProperty}
          selectedProperty={editProperty}
          properties={selectedEntity?.properties?.filter((p) => p.id !== editProperty.id) || []}
          onSave={onSaveProperty}
          onClose={() => setEditProperty(null)}
        />
      )}
      <ConfirmModal
        open={!!deleteProperty}
        title={t('delete_property')}
        message={t('bamboo_mess18', { propertyName: deleteProperty?.name || '' })}
        labelYes={t('delete')}
        size='small'
        onClose={() => setDeleteProperty(null)}
        onSave={() => onDeleteProperty(deleteProperty)}
        chatType={ChatTypeEnum.BAMBOO}
      />
      {openManageFile && (
        <ManageFileModal
          open={openManageFile}
          file={currentFile ?? null}
          onUpdate={handleFileUpdate}
          onClose={() => setOpenManageFile(false)}
          viewerId={`${pdfViewerId}-modal`}
        />
      )}
    </Page>
  )
}

export default ChatBamboo
