import { Icon, Button, InfoTooltip, sizeUnits } from '@aurecon-creative-technologies/styleguide'
import PDFViewer from '../components/PDFViewer'
import { useLanguages } from '../hooks/useLanguages'
import { Trans } from 'react-i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getBlob } from '../api/FileService'
import { ContentTypeToRealFileExtn } from '../enums/FileTypeEnum'
import { ResponseData } from '../models/api/IResponse'
import {
  DROPZONE_WIDTH,
  FILE_VIEWER_HEIGHT_DESKTOP,
  FILE_VIEWER_HEIGHT_FULL_WIDTH,
  FILE_VIEWER_HEIGHT_MOBILE,
  UI_FULL_WIDTH,
  UI_WIDTH_COLLAPSE,
} from './config'
import { useMediaQuery } from 'react-responsive'

export const LOADING = 'LOADING'
export const useFileUploadConfig = (props: {
  sizeLimit: number
  fileUnits: keyof typeof sizeUnits
  fileTypes: string[]
  file?: File | null
  fileUrl?: string
  onChange: (files: File[]) => void
  renderFile?: (renderFile?: File) => JSX.Element
  Style: { readonly [key: string]: string }
}) => {
  const { sizeLimit, fileUnits, fileTypes, file, onChange, Style, fileUrl } = props
  const { t } = useLanguages()

  const [fileFromUrl, setFileFromUrl] = useState<File | null>(null)

  const allowedFileExtensionsMessage = useMemo(() => {
    return t('bamboo_err5', { fileTypes: fileTypes.join(', ') })
  }, [fileTypes, t])

  const sizeLimitMessage = useMemo(() => {
    return t('bamboo_err4', { size: sizeLimit, unit: fileUnits })
  }, [fileUnits, sizeLimit, t])

  const DropzoneTooltip = useCallback(() => {
    const dropzoneFileTypes = fileTypes?.map((type) => type.replace('.', '').toUpperCase()).join(', ')
    const dropzoneSizeLimit = `${sizeLimit}${fileUnits.toLowerCase()}`

    return (
      <span className='tooltip'>
        <InfoTooltip
          show={
            <span style={{ textAlign: 'left' }}>
              <div>{t('upload_title2_tolltip')}</div>
              <div>{fileTypes?.join(', ')}</div>
            </span>
          }
        />
        {t('upload_title2', { fileTypes: dropzoneFileTypes, maxSize: dropzoneSizeLimit })}
      </span>
    )
  }, [fileTypes, fileUnits, sizeLimit, t])

  const DropzoneLabel = useCallback(() => {
    return (
      <div className={Style.dropzoneLabelContainer}>
        <div className={Style.dropzoneLabelIconContainer}>
          <Icon size={'36px'} type='picture_as_pdf' outlined cssClass='fileUploadIcon' />
        </div>
        <div className={Style.dropzoneLabelTextContainer}>
          <Trans i18nKey='upload_title' components={[<span key={0} className='browse' />]} />
        </div>
      </div>
    )
  }, [Style.dropzoneLabelContainer, Style.dropzoneLabelIconContainer, Style.dropzoneLabelTextContainer])

  const fileActionRenderer = (file: File, removeFile: (file: File) => void) => (
    <div className='file-uploader-delete-container'>
      <Button
        icon='delete'
        size='extra small'
        type='icon-round'
        cssClass='file-uploader-delete'
        onClick={() => removeFile(file)}
      />
    </div>
  )
  const isDesktop = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })
  const needFullWidth = useMediaQuery({ minWidth: UI_FULL_WIDTH })

  const viewerHeight = useMemo(() => {
    if (needFullWidth) return FILE_VIEWER_HEIGHT_FULL_WIDTH
    if (isDesktop) return FILE_VIEWER_HEIGHT_DESKTOP
    return FILE_VIEWER_HEIGHT_MOBILE
  }, [isDesktop, needFullWidth])

  const renderPDFViewer = useCallback(
    (file: File) => {
      return (
        <PDFViewer
          file={URL.createObjectURL(file)}
          showOnlyAsPreview={false}
          height={viewerHeight - 2}
          viewerId={`viewer-${file.name}`}
        />
      )
    },
    [viewerHeight],
  )

  useEffect(() => {
    setFileFromUrl(null)
    if (!fileUrl || fileUrl === LOADING) return
    const asyncEffect = async () => {
      const blob = ResponseData(await getBlob({ url: fileUrl }))
      if (blob === null) return
      const type = ContentTypeToRealFileExtn[blob.type] ?? 'jpg'
      const fileName = `${fileUrl.split('/').pop()?.split('?')[0]}.${type}`
      const file = new File([blob], fileName, { type: blob.type })
      setFileFromUrl(file)
    }
    asyncEffect()
  }, [fileUrl])

  const initialFiles = useMemo(() => {
    if (file) {
      return [file]
    }
    return fileFromUrl ? [fileFromUrl] : undefined
  }, [file, fileFromUrl])

  const onFileChange = useCallback(
    (files: File[]) => {
      if (files?.length === initialFiles?.length) {
        const allSame = files.every((file, index) => file === initialFiles[index])
        if (allSame) return // No change
      }
      onChange(files)
    },
    [initialFiles, onChange],
  )

  const fileUploadProps = useMemo(() => {
    return {
      initialFiles: !fileUrl && !file ? [] : initialFiles,
      dropzoneLabel: DropzoneLabel(),
      allowedFileExtensions: fileTypes,
      allowedFileExtensionsMessage,
      dropzoneTooltip: DropzoneTooltip(),
      onChange: onFileChange,
      fileRenderer: props.renderFile || renderPDFViewer,
      sizeLimitMessage,
      fileActionRenderer: fileActionRenderer,
      height: viewerHeight,
      width: DROPZONE_WIDTH,
    }
  }, [
    DropzoneLabel,
    DropzoneTooltip,
    allowedFileExtensionsMessage,
    file,
    fileTypes,
    fileUrl,
    initialFiles,
    onFileChange,
    props.renderFile,
    renderPDFViewer,
    sizeLimitMessage,
    viewerHeight,
  ])
  if (fileUrl && !fileFromUrl) return null
  return fileUploadProps
}
