import Joi from 'joi'
import { APP_SLIDE_DESCRIPTION_MAX_LENGTH } from '../config/config'

export const AppSlideFields = {
  position: 'position',
  language: 'language',
  sortOrder: 'sortOrder',
  subTitle: 'subTitle',
  title: 'title',
  description: 'description',
  descriptionText: 'descriptionText',
  learnMore: 'learnMore',
  url: 'url',
}

export const appSlideSchema = () => {
  return Joi.object({
    [AppSlideFields.position]: Joi.number().integer().min(1).max(3).required().messages({
      'number.empty': 'Position is required',
      'number.min': 'Position min is required',
      'number.max': 'Position max is required',
    }),
    [AppSlideFields.sortOrder]: Joi.number().integer().min(0).max(3).required().messages({
      'number.empty': 'Sort Order is required',
      'number.min': 'Sort Order min is required',
      'number.max': 'Sort Order max is required',
    }),
    [AppSlideFields.language]: Joi.string().trim().min(2).max(2).required().messages({
      'string.empty': 'Language is required',
      'string.min': 'Language min is required',
      'string.max': 'Language max is required',
    }),
    [AppSlideFields.title]: Joi.string().trim().min(1).max(20).required().messages({
      'string.empty': 'Cannot be empty',
      'string.min': 'Title min is required',
      'string.max': 'Title must be less than 20 characters',
    }),
    [AppSlideFields.subTitle]: Joi.string().trim().min(1).max(50).required().messages({
      'string.empty': 'Cannot be empty',
      'string.min': 'Mini Title min is required',
      'string.max': 'Mini Title must be less than 50 characters',
    }),
    [AppSlideFields.descriptionText]: Joi.string()
      .trim()
      .min(1)
      .max(APP_SLIDE_DESCRIPTION_MAX_LENGTH)
      .required()
      .messages({
        'string.empty': 'Cannot be empty',
        'string.min': 'Description min is required',
        'string.max': `Description must be less than ${APP_SLIDE_DESCRIPTION_MAX_LENGTH} characters`,
      }),
    [AppSlideFields.description]: Joi.string()
      .trim()
      .required()
      .messages({
        'string.empty': 'Cannot be empty',
        'string.min': 'Description min is required',
        'string.max': `Description must be less than <= ${APP_SLIDE_DESCRIPTION_MAX_LENGTH} characters`,
      }),
    [AppSlideFields.learnMore]: Joi.boolean().required().messages({
      'boolean.empty': 'Learn More is required',
    }),
    [AppSlideFields.url]: Joi.when('learnMore', {
      switch: [
        { is: true, then: Joi.string().trim().uri().required() },
        { is: false, then: Joi.string().trim().uri().allow('', null) },
      ],
    }).messages({
      'string.empty': 'Cannot be empty',
      'string.uri': 'Invalid URL',
    }),
  }).unknown(true)
}
