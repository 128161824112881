import React, { FC, useEffect, useContext, useState } from 'react'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'
import classNames from 'classnames'
import { Container, ThemeContext, ThemeEnum } from '@aurecon-creative-technologies/styleguide'

import Menu from './Menu'
import PageHeader from './PageHeader'
import QuestionBox from './QuestionBox'
import { ChatType, FullScreen, Language, ScrollChat, SystemBannerLoader } from '../stores/AppStore'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'

import Style from '../styles/Page.module.sass'
import { ISystemBannerInfo } from '../api/AppService'

export interface IPageProps {
  pageTitle?: string
  menu?: boolean
  children?: JSX.Element[] | JSX.Element | React.ReactNode
  contentWrapper?: boolean
  contentWrapperWide?: boolean
  noGrow?: boolean
  contentsRef?: React.RefObject<HTMLDivElement>
  cssClassName?: string
}

const Page: FC<IPageProps> = (props) => {
  const { theme } = useContext(ThemeContext)
  const language = useRecoilValue(Language)
  const chatType = useRecoilValue(ChatType)
  const fullScreen = useRecoilValue(FullScreen)
  const scrollChat = useRecoilValue(ScrollChat)
  const systemBannerLoader = useRecoilValueLoadable(SystemBannerLoader)
  const [systemBanner, setSystemBanner] = useState<ISystemBannerInfo>()

  useEffect(() => {
    if (!props.contentsRef?.current) return

    props.contentsRef.current.scrollTo({ top: props.contentsRef.current.scrollHeight, behavior: 'smooth' })
  }, [props.contentsRef, scrollChat])

  useEffect(() => {
    if (systemBannerLoader.state !== 'hasValue' || !systemBannerLoader.contents) return
    setSystemBanner(systemBannerLoader.contents)
  }, [systemBannerLoader.contents, systemBannerLoader.state])

  const message = systemBanner?.message

  const pageContentsClasses = classNames({
    [Style.pageContents]: true,
    [Style.fullscreen]: fullScreen,
    [Style.pageWideContents]: props.contentWrapperWide,
    [Style.noGrow]: props.noGrow,
  })

  const pageClasses = classNames({
    [Style.page]: true,
    pageThemeDark: theme === ThemeEnum.DARK,
    pageFontOpenSans: language === 'vi',
  })

  return (
    <div className={pageClasses}>
      {systemBanner?.enabled && <div className={Style.message} dangerouslySetInnerHTML={{ __html: message || '' }} />}
      <Container cssClass={Style.container}>
        <div className={Style.columns}>
          {props.menu && <Menu />}
          <div className={classNames(Style.mainPageWrapper, props.cssClassName, 'pageWrapper')} ref={props.contentsRef}>
            <PageHeader pageTitle={props.pageTitle} />
            {props.contentWrapper ? <div className={pageContentsClasses}>{props.children}</div> : <>{props.children}</>}
          </div>
          {chatType !== null && chatType !== ChatTypeEnum.BAMBOO && <QuestionBox />}
        </div>
      </Container>
    </div>
  )
}

export default Page
