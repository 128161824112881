import { FC, useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  FileUpload,
  FormInput,
  Grid,
  Icon,
  Loader,
  Pagination,
  Toggle,
  sizeUnits,
  useToast,
} from '@aurecon-creative-technologies/styleguide'
import { AppSlidePositionEnum } from '../enums/AppSlidePosition'

import { IAppSlide } from '../models/api/IHomepageModels'
import classNames from 'classnames'
import { useFileUploadConfig } from '../config/useFileUploadConfig'
import { APP_SLIDE_DESCRIPTION_MAX_LENGTH, APP_SLIDE_IMAGE_SIZE_MAX, MAX_SLIDES } from '../config/config'

import LanguageSwitch from './LanguageSwitch'
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { AppSlides, Language } from '../stores/AppStore'
import ConfirmModal from './modals/ConfirmModal'
import { useLanguages } from '../hooks/useLanguages'
import RecallJoditEditor from './common/RecallJoditEditor'

import { IErrorModel, getErrorsFromValidationResult } from '../validators/commonValidator'
import { appSlideSchema } from '../validators/appSlideValidator'
import { getTextFromHTML } from '../helpers/utils'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'

import Style from '../styles/AppSlideEditor.module.sass'
import { deleteAppSlide } from '../api/HomepageService'
import { ResponseData } from '../models/api/IResponse'

interface IAppSlideEditorProps {
  position: AppSlidePositionEnum
  onDirty: (slide: IAppSlide) => void
}

const joditCofig = {
  readonly: false,
  buttons: 'bold,italic,underline,strikethrough,fontsize,paragraph,lineHeight,superscript,subscript',
  statusbar: false,
  showTooltip: false,
  sizeLG: 12,
  sizeSM: 12,
  sizeMD: 12,
  disablePlugins: 'add-new-line',
}

const AppSlideEditor: FC<IAppSlideEditorProps> = (props) => {
  const { position } = props
  const AppSlidesLoader = useRecoilValueLoadable(AppSlides)
  const refreshAppSlides = useRecoilRefresher_UNSTABLE(AppSlides)

  const appLanguage = useRecoilValue(Language)
  const { t } = useLanguages()
  const { addToast } = useToast()

  const [language, setLanguage] = useState(appLanguage || 'en')
  const [sortOrder, setSortOrder] = useState(0)

  const [allSlidesForPosition, setAllSlidesForPosition] = useState<IAppSlide[] | null>(null)

  const [editing, setEditing] = useState<IAppSlide | null>(null)
  const [pageCount, setPageCount] = useState(1)

  const [removeCurrentSlide, setRemoveCurrentSlide] = useState(false)
  const [errors, setErrors] = useState<IErrorModel>({})

  useMemo(() => {
    if (AppSlidesLoader.state !== 'hasValue' || !AppSlidesLoader.contents) return
    setAllSlidesForPosition(AppSlidesLoader.contents.filter((slide) => slide.position === position))
  }, [AppSlidesLoader.contents, AppSlidesLoader.state, position])

  useEffect(() => {
    if (!allSlidesForPosition || allSlidesForPosition.length > 10) return

    if (editing) {
      const index = allSlidesForPosition.findIndex(
        (slide) =>
          slide.sortOrder === editing.sortOrder &&
          slide.language === editing.language &&
          slide.position === editing.position,
      )
      if (index !== -1) {
        allSlidesForPosition[index] = editing
      }
      if (editing.sortOrder === sortOrder && editing.language === language && editing.position === position) {
        return
      }
    }

    const foundOrder = allSlidesForPosition.filter((slide) => slide.sortOrder === sortOrder)
    const foundLanguage = foundOrder.find((slide) => slide.language === language)
    if (foundLanguage) {
      setEditing({
        ...foundLanguage,
        descriptionText: getTextFromHTML(foundLanguage.description),
      })
      return
    }

    const english = foundOrder.find((slide) => slide.language === 'en')

    const addSlide = english ? { ...english, language } : emptySlide(position, language, sortOrder)
    setAllSlidesForPosition((prev) => {
      if (prev) {
        return [...prev, addSlide]
      }
      return [addSlide]
    })
    setErrors({})

    setEditing(addSlide)
  }, [allSlidesForPosition, editing, language, position, sortOrder])

  const emptySlide = (position: AppSlidePositionEnum, language: string, sortOrder: number): IAppSlide => {
    return {
      position,
      language,
      sortOrder,
      fileSaS: '',
      url: '',
      title: '',
      subTitle: '',
      description: '',
      descriptionText: '',
      image: '',
      learnMore: false,
    }
  }

  const onLanguageChange = (value: string) => {
    setLanguage(value)
  }

  const onChange = (value: string | boolean, name: string) => {
    setEditing((prev) => {
      if (prev) {
        const newSlide = { ...prev, [name]: value }
        props.onDirty(newSlide)
        return newSlide
      }
      return null
    })
  }

  useEffect(() => {
    const validationResult = appSlideSchema().validate(editing, { abortEarly: false })
    const errs = getErrorsFromValidationResult(validationResult)
    setErrors(errs)
  }, [editing, props])

  const onBlur = () => {
    if (props.onDirty && editing) props.onDirty({ ...editing, error: errors })
  }

  useEffect(() => {
    if (allSlidesForPosition && editing) {
      const length = allSlidesForPosition.filter((a) => a.position === editing.position).length
      setPageCount(length)
    }
  }, [allSlidesForPosition, editing])

  const changePage = (page: number) => {
    setSortOrder(page - 1)
  }

  const handleFileChange = (files: File[]) => {
    const newFile = files.length ? files[0] : null
    setEditing((prev) => {
      if (prev) {
        const newSlide = { ...prev, file: newFile, fileSaS: '', image: newFile?.name ?? '' }
        props.onDirty(newSlide)
        return newSlide
      }
      return null
    })
  }
  const sizeLimit = APP_SLIDE_IMAGE_SIZE_MAX
  const fileUnits = sizeUnits.MegaByte

  const fileCache = useRef<{ fileName: string; objUrl: string }[]>([])

  const renderIMG = (file?: File) => {
    if (!file) return <Icon size='64px' type='image' outlined cssClass='fileUpload' />

    const found = fileCache.current.find((cache) => cache.fileName === file?.name)
    if (found) {
      return <img src={found.objUrl} alt='preview' role='none' />
    }
    const objUrl = URL.createObjectURL(file)
    fileCache.current.push({ fileName: file?.name || '', objUrl })

    return <img src={objUrl} alt='preview' role='none' />
  }

  const fileUploadProps = useFileUploadConfig({
    sizeLimit,
    fileUnits: fileUnits.label as keyof typeof sizeUnits,
    fileTypes: ['.jpg', '.jpeg', '.png'],
    file: editing?.file,
    fileUrl: editing?.fileSaS,
    onChange: handleFileChange,
    renderFile: renderIMG,
    Style,
  })

  const slideClasses = classNames({
    [Style.appSlide]: true,
    [Style.main]: position === AppSlidePositionEnum.MAIN,
    [Style.left]: position === AppSlidePositionEnum.LEFT,
    [Style.right]: position === AppSlidePositionEnum.RIGHT,
  })

  const addNewSlide = () => {
    setSortOrder(pageCount)
  }

  const removeSlide = () => {
    setRemoveCurrentSlide(true)
  }

  const disableDelete = useMemo(() => {
    if (AppSlidesLoader.state !== 'hasValue' || !AppSlidesLoader.contents) return
    const hasSaved = AppSlidesLoader.contents?.find((a) => a.sortOrder === editing?.sortOrder)
    return !hasSaved
  }, [AppSlidesLoader.contents, AppSlidesLoader.state, editing?.sortOrder])

  const deleteSlide = async () => {
    if (editing) {
      const res = ResponseData(await deleteAppSlide({ sortOrder, position }))
      if (!res) {
        addToast({
          type: 'error',
          message: 'There was an issue deleting app slide.',
          timeout: 5000,
        })
        setRemoveCurrentSlide(false)

        return
      }

      addToast({
        type: 'success',
        message: 'App slide successfully removed',
        timeout: 5000,
      })

      setEditing(null)
      refreshAppSlides()
    }
    setRemoveCurrentSlide(false)
  }

  return (
    <div className={Style.appSlideHolder}>
      <ConfirmModal
        open={!!removeCurrentSlide}
        title={t('delete_slide_title')}
        message={t('delete_slide_desc')}
        labelYes={t('delete')}
        size='small'
        onClose={() => setRemoveCurrentSlide(false)}
        onSave={() => deleteSlide()}
        chatType={ChatTypeEnum.NONE}
      />

      {!allSlidesForPosition ? (
        <Loader label='loading' />
      ) : (
        <>
          <div className={slideClasses}>
            {position === AppSlidePositionEnum.MAIN && (
              <div className={Style.image}>
                {fileUploadProps?.initialFiles ? (
                  <FileUpload
                    {...fileUploadProps}
                    sizeLimit={[sizeLimit, fileUnits]}
                    height={`${fileUploadProps.height}px`}
                  />
                ) : (
                  <Loader label='loading image' />
                )}
              </div>
            )}

            <div className={Style.details}>
              <Grid row gap={12} cssClass={Style.form}>
                <Grid item xs={12}>
                  <FormInput
                    required
                    placeholder={'Enter title...'}
                    value={editing?.subTitle}
                    onChange={(value) => onChange(value, 'subTitle')}
                    onBlur={() => onBlur()}
                    label='Mini Title'
                    error={errors.subTitle?.[0]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInput
                    required
                    placeholder={'Enter title...'}
                    value={editing?.title}
                    onChange={(value) => onChange(value, 'title')}
                    onBlur={() => onBlur()}
                    label='Title'
                    error={errors.title?.[0]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RecallJoditEditor
                    value={editing?.description ?? ''}
                    config={joditCofig}
                    onBlur={(value) => {
                      onChange(value, 'description')
                      onBlur()
                    }} // preferred to use only this option to update the content for performance reasons
                    maxLength={APP_SLIDE_DESCRIPTION_MAX_LENGTH}
                    onTextChange={(value) => onChange(value, 'descriptionText')}
                    error={errors.descriptionText?.[0]}
                    label='Description'
                    required
                  />
                </Grid>
                {position !== AppSlidePositionEnum.MAIN && (
                  <Grid item xs={12}>
                    <div className={Style.image}>
                      {fileUploadProps?.initialFiles ? (
                        <FileUpload
                          {...fileUploadProps}
                          sizeLimit={[sizeLimit, fileUnits]}
                          height={`${fileUploadProps.height}px`}
                        />
                      ) : (
                        <Loader label='loading image' />
                      )}
                    </div>
                  </Grid>
                )}
                {position === AppSlidePositionEnum.MAIN && (
                  <Grid item xs={12}>
                    <Toggle
                      label='Learn More Button'
                      value={editing?.learnMore}
                      onChange={(value) => {
                        onChange(value, 'learnMore')
                        onBlur()
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormInput
                    required={editing?.learnMore}
                    placeholder={'e.g. https://www.aurecongroup.com'}
                    value={editing?.url || ''}
                    onChange={(value) => onChange(value, 'url')}
                    onBlur={() => onBlur()}
                    label='URL'
                    error={errors.url?.[0]}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={Style.appSlideFooter}>
            {position === AppSlidePositionEnum.MAIN ? (
              <>
                <div>
                  <Button
                    label='Add New Slide'
                    size='small'
                    type='primary'
                    disabled={pageCount >= MAX_SLIDES}
                    onClick={() => addNewSlide()}
                  />
                  <Button
                    icon='delete'
                    size='small'
                    type='icon-round'
                    default
                    onClick={() => removeSlide()}
                    disabled={pageCount === 1 || disableDelete}
                  />
                </div>
                <div>
                  <LanguageSwitch value={editing?.language || language} onChange={onLanguageChange} />
                  <div className={Style.pageCount}>{`${sortOrder + 1} of ${pageCount}`}</div>
                  <Pagination page={sortOrder + 1} pageCount={pageCount} onChange={changePage} minimal smallChevron />
                </div>
              </>
            ) : (
              <div>
                <LanguageSwitch value={editing?.language || language} onChange={onLanguageChange} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default AppSlideEditor
