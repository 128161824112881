import { CSSProperties, FC, useMemo, useState } from 'react'
import { Button, Grid, Icon, Loader } from '@aurecon-creative-technologies/styleguide'
import { AppSlidePositionEnum } from '../enums/AppSlidePosition'

import { IAppSlide } from '../models/api/IHomepageModels'
import classNames from 'classnames'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { AppSlides, Language } from '../stores/AppStore'

import Style from '../styles/AppSlideEditor.module.sass'

interface IAppSlideEditorProps {
  position: AppSlidePositionEnum
}

const AppSlideViewer: FC<IAppSlideEditorProps> = (props) => {
  const { position } = props
  const AppSlidesLoader = useRecoilValueLoadable(AppSlides)
  const appLanguage = useRecoilValue(Language)

  const [allSlidesForPosition, setAllSlidesForPosition] = useState<IAppSlide[] | null>(null)
  const [current, setCurrent] = useState<IAppSlide | null>(null)
  const [mainSlides, setMainSlides] = useState<IAppSlide[]>([])

  useMemo(() => {
    if (AppSlidesLoader.state !== 'hasValue' || !AppSlidesLoader.contents) return
    const slides = AppSlidesLoader.contents.filter(
      (slide) => slide.position === position && slide.language === appLanguage,
    )
    const sortedSlides = slides.sort((a, b) => a.sortOrder - b.sortOrder)

    if (sortedSlides.length) {
      setCurrent(sortedSlides[0])
    }

    if (position === AppSlidePositionEnum.MAIN && slides.length) {
      const sortedSlides = slides.sort((a, b) => a.sortOrder - b.sortOrder)
      setMainSlides(sortedSlides)
    }
    setAllSlidesForPosition(sortedSlides)
  }, [AppSlidesLoader.contents, AppSlidesLoader.state, appLanguage, position])

  const slideClasses = classNames({
    [Style.appSlide]: true,
    [Style.main]: position === AppSlidePositionEnum.MAIN,
    [Style.left]: position === AppSlidePositionEnum.LEFT,
    [Style.right]: position === AppSlidePositionEnum.RIGHT,
  })
  const slideHolderClasses = classNames({
    [Style.appSlideHolder]: true,
    [Style.viewer]: true,
  })

  const onLearnMore = () => {
    if (current?.url) window.open(current?.url, '_blank')
  }

  const style = useMemo(() => {
    return {
      backgroundImage: `url(${current?.fileSaS})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    } as CSSProperties
  }, [current?.fileSaS])

  if (!allSlidesForPosition)
    return (
      <div className={slideHolderClasses}>
        <Loader label='loading' />
      </div>
    )

  const onDotClick = (sortOrder: number) => {
    const slide = mainSlides.find((s) => s.sortOrder === sortOrder)
    if (!slide) return
    setCurrent(slide)
  }

  if (!allSlidesForPosition.length) return null

  return (
    <div className={slideHolderClasses}>
      <div className={slideClasses} style={position === AppSlidePositionEnum.MAIN ? style : undefined}>
        <div className={Style.push} />
        <div className={Style.details}>
          <Grid row gap={12} cssClass={Style.form}>
            <Grid item xs={12} cssClass={Style.subTitle}>
              {current?.subTitle}
            </Grid>
            <Grid item xs={12} cssClass={Style.title}>
              {current?.title}
            </Grid>
            <Grid item xs={12} cssClass={Style.description}>
              <div dangerouslySetInnerHTML={{ __html: current?.description || '' }} />
            </Grid>
            {position === AppSlidePositionEnum.MAIN && current?.learnMore && (
              <Grid item xs={12}>
                <Button label='Learn More' size='small' type='primary' disabled={!current.url} onClick={onLearnMore} />
              </Grid>
            )}
          </Grid>
        </div>
        {position !== AppSlidePositionEnum.MAIN && (
          <div className={Style.image}>
            <img src={current?.fileSaS} alt='slide' role='none' />
          </div>
        )}
        {position === AppSlidePositionEnum.MAIN && mainSlides.length > 1 && (
          <div className={Style.dots}>
            {mainSlides.map((slide) => (
              <Icon
                key={slide.sortOrder}
                type='circle'
                size='18px'
                onClick={() => onDotClick(slide.sortOrder)}
                colour={current?.sortOrder === slide.sortOrder ? '#85C446' : '#ffffff50'}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default AppSlideViewer
